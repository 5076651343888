var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { on, exitFullScreen, enterFullScreen } from '..';
import { getScriptParams, dispatch } from '../../utils';
import { onMessageEvent } from '../../utils/onMessageEvent';
import { resizeJourney } from '../resizeJourney';
import { initByOptions } from './initByOptions';
import { initByParams } from './initByParams';
var isSetupCompleted = false;
var setSetupCompleted = function () { return (isSetupCompleted = true); };
export var init = function (options, initOnLoad) {
    if (initOnLoad === void 0) { initOnLoad = false; }
    // init via options
    if (options && options.length > 0) {
        var journeys = initByOptions(options);
        // dispatch init event
        dispatch('init', { journeys: journeys });
        return;
    }
    else {
        var _a = getScriptParams(), params = _a.params, data = _a.data;
        if (params) {
            // true if should auto initialise + dataInit not false, or if manual init
            var executeInit = initOnLoad && params.init ? true : !initOnLoad ? true : false;
            if (executeInit) {
                // TODO: Add logging to help deprecate/remove this legacy code
                // init via params
                var journeys = initByParams(params, data);
                // dispatch init event
                dispatch('init', { journeys: journeys });
            }
        }
    }
    // setup default listeners
    if (!isSetupCompleted) {
        // listen for iframe message events
        window.addEventListener('message', function (e) { return onMessageEvent(e); }, false);
        // set up required event listeners
        on('exitFullScreen', function (_a) {
            var journeyId = _a.journeyId, payload = __rest(_a, ["journeyId"]);
            if (typeof journeyId === 'string') {
                return exitFullScreen(journeyId, payload);
            }
        });
        on('enterFullScreen', function (_a) {
            var journeyId = _a.journeyId, payload = __rest(_a, ["journeyId"]);
            if (typeof journeyId === 'string') {
                return enterFullScreen(journeyId, payload);
            }
        });
        on('pageView', function (_a) {
            var journeyId = _a.journeyId, payload = __rest(_a, ["journeyId"]);
            if (typeof journeyId === 'string') {
                return resizeJourney(journeyId, payload);
            }
        });
        setSetupCompleted();
    }
};
